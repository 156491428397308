<template>
  <div>
    <b-col>
      <b-row>
        <b-col md="12">
          <b-card>
            <b-row class="justify-content-between">
              <b-col>
                <b-row>
                  <b-col>
                    <h5><strong>Cadastro de Versões</strong></h5>
                  </b-col>
                </b-row>
                <hr>
              </b-col>
            </b-row>
            <b-row>

              <b-col>
                <label for=""><strong>Versão: </strong></label>
                <b-form-input
                  v-model="form.numero_versao"
                  type="text"
                />
              </b-col>

            </b-row>
            <b-row class="mt-1">
              <b-col md="12">
                <label for=""><strong>Descrição: </strong></label>

                <quill-editor
                  v-model="form.descricao"
                />

              </b-col>
            </b-row>
            <b-button
              class="mt-2"
              type="submit"
              variant="primary"
              @click="submit"
            >
              Cadastrar
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

// import { addYears } from 'date-fns'

// imports do text-area customizado
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    quillEditor,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        numero_versao: '',
        descricao: '',
      },
    }
  },
  methods: {
    submit() {
      axios.post('api/v1/versao/store', this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.token),
          Accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Cadastro realizado',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: 'Ops, cadastro não realizado',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
          this.clearForm()
        })
    },
    clearForm() {
      this.form = {
        numero_versao: '',
        descricao: '',
      }
    },
  },
}
</script>
