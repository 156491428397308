<template>
  <div>
    <b-tabs
      vertical
      pills
    >
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Usuário</span>
        </template>
        <User />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Permissões</span>
        </template>
        <Permissoes />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Versões</span>
        </template>
        <Versao />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'
import User from './tabs/UsuarioCadastro.vue'
import Permissoes from './tabs/PermissoesCadastro.vue'
import Versao from './tabs/VersaoCadastro.vue'

export default {
  components: {
    Permissoes,
    User,
    Versao,
    BTabs,
    BTab,
  },
}
</script>
