<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          v-b-modal.endpointModal
          variant="primary"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Endpoint
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-modal.permissoesModal
          variant="primary"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Perfil
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="permissoesModal"
      ok-title="Salvar"
      cancel-variant="danger"
      cancel-title="Cancelar"
      size="lg"
      @show="limparModalPerfil"
      @ok="storePerfil"
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
        >
          Perfil :
          <b-form-input
            v-model="permissoes.perfil"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Endpoint :
          <b-form-input
            v-model="filterPerfilEndpoint"
            class="mb-2"
            placeholder="Filtrar"
          />
          <b-table
            respondive
            sticky-header
            bordered
            striped
            small
            :filter="filterPerfilEndpoint"
            :fields="perfilField"
            :items="endpointItems"
          >
            <template #head(check)>
              <b-form-checkbox
                v-model="selectAllPerfis"
              />
            </template>
            <template #cell(check)="data">
              <b-form-checkbox
                v-model="permissoes.endpoints"
                :value="data.item.id"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <!-- MODAL ENDPOINT -->
    <b-modal
      id="endpointModal"
      ok-title="Salvar"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @show="clearModalEndpoint"
      @ok="storeEndpoint"
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
        >
          Grupo :
          <b-form-input
            v-model="endpoint.grupo"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Nome :
          <b-form-input
            v-model="endpoint.nome"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          URL :
          <b-form-input
            v-model="endpoint.url"
          />
        </b-col>
      </b-row>
    </b-modal>
    <!-- MODAL PERFIL EDICAO -->
    <b-modal
      ref="modalPerfilEdicao"
      hide-footer
      size="lg"
      @show="perfilEdit=true"
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
        >
          Perfil :
          <b-form-input
            v-model="permissoes.perfil"
            :disabled="perfilEdit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Endpoint :
          <b-form-input
            v-model="filterPerfilEndpoint"
            class="mb-2"
            placeholder="Filtrar"
          />
          <b-table
            respondive
            sticky-header
            bordered
            striped
            small
            :filter="filterPerfilEndpoint"
            :fields="perfilField"
            :items="endpointItems"
          >
            <template #head(check)>
              <b-form-checkbox
                v-model="selectAllPerfis"
                :disabled="perfilEdit"
              />
            </template>
            <template #cell(check)="data">
              <b-form-checkbox
                v-model="permissoes.endpoints"
                :value="data.item.id"
                :disabled="perfilEdit"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="perfilEdit"
            variant="outline-primary"
            @click="perfilEdit=false"
          >
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!perfilEdit"
            variant="outline-secondary"
            @click="$refs.modalPerfilEdicao.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="danger"
            @click="deletePerfil"
          >
            Excluir
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="updatePerfil"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- DIV ABAS -->
    <div>
      <b-tabs pills>
        <b-tab title="Endpoint">
          <div class="mt-2">
            <b-card>
              <b-row
                class="mt-2 mb-2"
              >
                <b-col>
                  <b-form-input
                    v-model="filterEndpoint"
                    type="search"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                striped
                per-page="10"
                :current-page="currentPageEndpoint"
                :filter="filterEndpoint"
                :fields="endpointFields"
                :items="endpointItems"
                @row-clicked="endpointUpdate"
                @filtered="onFiltered"
              />
              <b-modal
                ref="endpointUpdate"
                hide-footer
                @show="endpointEdit=true"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    Grupo :
                    <b-form-input
                      v-model="endpoint.grupo"
                      :disabled="endpointEdit"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    Nome :
                    <b-form-input
                      v-model="endpoint.nome"
                      :disabled="endpointEdit"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-2"
                  >
                    URL :
                    <b-form-input
                      v-model="endpoint.url"
                      :disabled="endpointEdit"
                    />
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <b-button
                      v-show="endpointEdit"
                      variant="outline-primary"
                      @click="endpointEdit=false"
                    >
                      Editar
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      v-show="!endpointEdit"
                      variant="outline-danger"
                      @click="$refs.endpointUpdate.hide()"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="danger"
                      @click="endpointDelete"
                    >
                      Excluir
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="primary"
                      @click="endpointPut"
                    >
                      Salvar
                    </b-button>
                  </b-col>
                </b-row>
              </b-modal>
              <b-pagination
                v-model="currentPageEndpoint"
                :total-rows="totalRowsEndpoint"
                per-page="10"
                align="center"
                size="sm"
              />
            </b-card>
          </div>
        </b-tab>
        <!-- ABA PERMISSOES -->
        <b-tab
          title="Perfil"
        >
          <div>
            <b-card>
              <b-row class="mt-2 mb-2">
                <b-col cols="12">
                  <b-form-input
                    v-model="filterPermissoes"
                    placeholder="Pesquisar"
                  />
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                striped
                :per-page="perPage"
                :current-page="currentPagePermissoes"
                :filter="filterPermissoes"
                :fields="permissoesFields"
                :items="permissoesItems"
                @row-clicked="modalPerfilEdicao"
                @filtered="filterRowsPermission"
              />
              <b-pagination
                v-model="currentPagePermissoes"
                :total-rows="totalRowsPermissoes"
                :per-page="perPage"
                align="center"
                size="sm"
              />
            </b-card>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
  BFormInput,
  BFormCheckbox,
  BTable,
  BCard,
  BPagination,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BModal,
    BFormInput,
    BFormCheckbox,
    BTable,
    BCard,
    BPagination,
    BTab,
    BTabs,
  },

  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      endpointFields: [
        'nome',
        'grupo',
        'url',
      ],
      endpointItems: [],
      filterEndpoint: '',
      perPage: 10,
      currentPageEndpoint: 1,
      totalRowsEndpoint: 1,
      endpointEdit: true,
      endpoint: {
        nome: '',
        url: '',
        grupo: '',
      },
      selectAllPerfis: Boolean,
      filterPerfilEndpoint: '',
      perfilSelected: [],
      perfilField: [
        'grupo',
        'nome',
        'check',
      ],
      permissoesFields: [],
      permissoesItems: [],
      filterPermissoes: '',
      currentPagePermissoes: 1,
      totalRowsPermissoes: 1,
      perfilId: '',
      perfilEdit: true,
      permissoes: {
        perfil: '',
        endpoints: [],
      },
    }
  },

  watch: {
    selectAllPerfis(n) {
      n ? this.endpointItems.map(dt => {
        this.permissoes.endpoints.push(dt.id)
      }) : this.permissoes.endpoints = []
    },
  },

  async created() {
    await this.getPermissoes()
    await this.getEndpoint()
  },

  methods: {
    filterRowsPermission(filteredItems) {
      this.totalRowsPermissoes = filteredItems.length
      this.currentPagePermissoes = 1
    },
    onFiltered(filteredItems) {
      this.totalRowsEndpoint = filteredItems.length
      this.currentPageEndpoint = 1
    },
    updatePerfil() {
      axios.put(`api/v1/perfil_usuario/update/${this.perfilId}`, this.permissoes, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$refs.modalPerfilEdicao.hide()
          this.getPermissoes()
          this.$swal({
            title: 'Perfil Atualizado',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$refs.modalPerfilEdicao.hide()
          this.getPermissoes()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro durante a operação!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
    deletePerfil() {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios.delete(`api/v1/perfil_usuario/delete/${this.perfilId}`, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            })
              .then(() => {
                this.$refs.modalPerfilEdicao.hide()
                this.getPermissoes()
                this.$swal({
                  title: 'Perfil Excluido',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                })
              })
              .catch(() => {
                this.$refs.modalPerfilEdicao.hide()
                this.getPermissoes()
                this.$swal({
                  title: 'ERRO',
                  icon: 'error',
                  text: 'Ocorreu um erro durante a operação',
                  customClass: {
                    confirmButtonVariant: 'btn btn-primary',
                  },
                })
              })
          }
        })
    },
    modalPerfilEdicao(item) {
      this.perfilId = item.id
      this.permissoes = {
        perfil: '',
        endpoints: [],
      }
      axios.get(`api/v1/perfil_usuario/edit/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          const { dados } = res.data
          this.permissoes.perfil = dados.perfil
          if (dados.endpoints) {
            dados.endpoints.map(endpt => {
              this.permissoes.endpoints.unshift(endpt.id)
            })
          }
        })
      this.$refs.modalPerfilEdicao.show()
    },
    limparModalPerfil() {
      this.permissoes = {
        perfil: '',
        endpoints: [],
      }
    },
    storePerfil() {
      axios.post('api/v1/perfil_usuario/store', this.permissoes, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.getPermissoes()
          this.$swal({
            title: 'Novo perfil criado!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.getPermissoes()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro ao durante a operação',
            customClass: {
              confirmButtonVariant: 'btn btn-danger',
            },
          })
        })
    },

    endpointPut() {
      const body = {
        nome: this.endpoint.nome,
        url: this.endpoint.url,
        grupo: this.endpoint.grupo,
      }
      axios.put(`api/v1/endpoints/update/${this.endpoint.id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$refs.endpointUpdate.hide()
          this.getEndpoint()
          this.$swal({
            title: 'Endpoint atualizado',
            icon: 'success',
            timer: 1500,
            showCancelButtonyarn: false,
          })
        })
        .catch(() => {
          this.$refs.endpointUpdate.hide()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Erro ao atualizar endpoint',
            customClass: {
              confirmButtonVariant: 'btn btn-danger',
            },
          })
        })
    },

    endpointDelete() {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero excluir!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios.delete(`api/v1/endpoints/delete/${this.endpoint.id}`, {
              headers: {
                Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                accept: 'application/json',
              },
            })
              .then(() => {
                this.$refs.endpointUpdate.hide()
                this.getEndpoint()
                this.$swal({
                  title: 'Endpoint Excluido',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                })
              })
              .catch(() => {
                this.$refs.endpointUpdate.hide()
                this.$swal({
                  title: 'ERRO',
                  icon: 'error',
                  text: 'Ocorreu um erro',
                  customClass: {
                    confirmButtonVariant: 'btn btn-danger',
                  },
                })
              })
          } else {
            this.$refs.endpointUpdate.hide()
          }
        })
    },

    getPermissoes() {
      this.permissoesItems = []
      axios.get('api/v1/perfil_usuario/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.permissoesItems.unshift({
              id: dt.id,
              perfil: dt.descricao,
            })
          })
          this.totalRowsPermissoes = this.permissoesItems.length
        })
    },

    clearModalEndpoint() {
      this.endpoint = {
        nome: '',
        url: '',
        grupo: '',
      }
    },

    endpointUpdate(item) {
      this.endpoint = item
      this.$refs.endpointUpdate.show()
    },

    storeEndpoint() {
      axios.post('api/v1/endpoints/store', this.endpoint, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.getEndpoint()
        this.$swal({
          title: 'Novo endpoint salvo!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            text: 'Ocorreu um erro ao durante a operação',
            customClass: {
              confirmButtonVariant: 'btn btn-danger',
            },
          })
        })
    },

    getEndpoint() {
      this.endpointItems = []
      axios.get('api/v1/endpoints/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.endpointItems.push({
            id: dt.id,
            nome: dt.nome,
            url: dt.url,
            grupo: dt.grupo,
          })
        })
        this.totalRowsEndpoint = this.endpointItems.length
      })
    },
  },
}
</script>
