<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          v-b-modal.userModal
          variant="primary"
        >
          Atribuir Permissões
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-modal.novo-usuario
          class="mb-2"
          variant="primary"
        >
          Novo Usuário
        </b-button>
      </b-col>
    </b-row>
    <!-- MODAL PERMISSOES -->
    <b-modal
      id="userModal"
      title="Atribuir Permissão"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      ok-title="Salvar"
      @show="limparPermissoes"
      @ok="storeAcessoPermissao"
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
        >
          Usuário :
          <v-select
            v-model="atribuirPermissao.usuario"
            label="text"
            :options="optionsUser"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          Perfil :
          <v-select
            v-model="atribuirPermissao.perfil"
            label="text"
            :options="optionsPerfil"
          />
        </b-col>
      </b-row>
    </b-modal>
    <!-- MODAL CADASTRO USUARIO -->
    <b-modal
      id="novo-usuario"
      ref="novo-usuario"
      no-close-on-backdrop
      title="Usuário Avulso"
      ok-title="Criar Uasuário"
      cancel-variant="danger"
      @ok="novoUsuario"
      @show="limparModal"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Nome:
          <b-form-input
            v-model="user.name"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Email
          <b-form-input
            v-model="user.email"
            placeholder=""
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          CPF:
          <cleave
            v-model="user.cpf"
            class="form-control"
            :raw="false"
            :options="mask.cpf"
            placeholder="000.000.000-00"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Senha:
          <b-form-input
            v-model="user.password"
            type="password"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Empresa:
          <v-select
            v-model="user.empresa_id"
            label="text"
            :options="empresas"
          />
        </b-col>
      </b-row>
    </b-modal>

    <b-card>
      <b-row
        align-h="between"
        class="mb-2"
      >
        <b-col
          cols="1"
        >
          <b-form-select
            v-model="perPage"
            label="text"
            :options="[5, 10, 15]"
            size="sm"
          />
        </b-col>
        <b-col
          lg="8"
          class="d-flex justify-content-end"
        >
          <b-form-input
            v-model="filter"
            :options="[10, 15, 20]"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        responsive
        hover
        bordered
        striped
        :per-page="perPage"
        :filter="filter"
        :current-page="currentPage"
        :fields="fieldsUser"
        :items="itemsUser"
        @filtered="onFiltered"
      />
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
      />
    </b-card>

  </div>
</template>
<script>
import {
  BTable,
  BCard,
  BPagination,
  BCol,
  BRow,
  BFormSelect,
  BFormInput,
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    Cleave,
    BTable,
    BCard,
    BPagination,
    BFormSelect,
    BCol,
    BRow,
    vSelect,
    BFormInput,
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {

      atribuirPermissao: {
        usuario: '',
        perfil: '',
      },
      optionsUser: [],
      optionsPerfil: [],

      filter: '',
      fieldsUser: [],
      itemsUser: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      funcionario: {
        options: [],
        selected: null,
      },
      empresas: [],

      user: {
        name: null,
        email: null,
        cpf: null,
        password: null,
        url_perfil: null,
        status: true,
        empresa_id: null,
      },
      mask: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
      },
    }
  },

  async created() {
    this.listaUsuarios()
    this.listaEmpresa()
    this.getUsers()
    this.getPerfils()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async storeAcessoPermissao() {
      const body = {
        perfil: this.atribuirPermissao.perfil.value,
        usuario: this.atribuirPermissao.usuario.value,
      }
      await axios.post('api/v1/users/atache/perfil', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.listaUsuarios()
          this.$swal({
            title: 'Permissoes de acesso atribuidas com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.listaUsuarios()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro durante a operação!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },

    limparPermissoes() {
      this.atribuirPermissao = {
        usuario: '',
        perfil: '',
      }
    },
    async getPerfils() {
      await axios.get('api/v1/perfil_usuario/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.optionsPerfil.unshift({
              value: dt.id,
              text: dt.descricao,
            })
          })
        })
    },

    async getUsers() {
      // lista usuarios
      await axios.get('api/v1/users', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.optionsUser.push({
              value: dt.id,
              text: dt.name,
            })
          })
        })
    },
    limparModal() {
      this.user = {
        name: null,
        email: null,
        cpf: null,
        password: null,
        url_perfil: null,
        status: true,
        empresa_id: null,
      }
    },

    async novoUsuario() {
      const body = {
        name: this.user.name,
        email: this.user.email,
        cpf: (this.user.cpf).replace(/[^0-9]/g, ''),
        password: this.user.password,
        url_perfil: this.user.url_perfil,
        status: true,
        empresa_id: this.user.empresa_id,
      }
      await axios.post('api/v1/users/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'CADASTRADO!',
          text: 'Usuário cadastrado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
        })
        this.listaUsuarios()
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao cadsastrar usuário!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
    async listaEmpresa() {
      await axios.get('api/v1/empresas', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        res.data.dados.empresa.map(dt => {
          this.empresas.push({
            text: dt.nome_fantasia,
            value: dt.id,
          })
        })
      })
    },
    async listaUsuarios() {
      this.itemsUser = []
      await axios.get('api/v1/users', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(async res => {
        await res.data.dados.map(dt => (
          this.itemsUser.unshift({
            id: dt.id,
            nome: dt.name,
            perfil: dt.perfil.toString(),
            email: dt.email,
            cpf: this.cpfCNPJ(dt.cpf),
          })
        ))
      })
      this.totalRows = this.itemsUser.length
    },
  },

}

</script>
